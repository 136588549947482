import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1cf2d597 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "" */))
const _11cb3318 = () => interopDefault(import('../pages/authorization/index.vue' /* webpackChunkName: "pages/authorization/index" */))
const _45f523c6 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _3c38f614 = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _5a81a403 = () => interopDefault(import('../pages/partner/index.vue' /* webpackChunkName: "pages/partner/index" */))
const _29a528b8 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _36a7d318 = () => interopDefault(import('../pages/registration/index.vue' /* webpackChunkName: "pages/registration/index" */))
const _8e4f7c58 = () => interopDefault(import('../pages/request-password/index.vue' /* webpackChunkName: "pages/request-password/index" */))
const _7c6946ba = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _121b0877 = () => interopDefault(import('../pages/success-payment/index.vue' /* webpackChunkName: "pages/success-payment/index" */))
const _0c6e4b8e = () => interopDefault(import('../pages/countries/ind222ex__.vue' /* webpackChunkName: "pages/countries/ind222ex__" */))
const _3b0c9864 = () => interopDefault(import('../pages/marketplace/account/index.vue' /* webpackChunkName: "pages/marketplace/account/index" */))
const _34b97ab8 = () => interopDefault(import('../pages/marketplace/registration/index.vue' /* webpackChunkName: "pages/marketplace/registration/index" */))
const _4d3ad218 = () => interopDefault(import('../pages/partner/activations-history/index.vue' /* webpackChunkName: "pages/partner/activations-history/index" */))
const _3056e8f1 = () => interopDefault(import('../pages/partner/price-regulator/index.vue' /* webpackChunkName: "pages/partner/price-regulator/index" */))
const _73b7d068 = () => interopDefault(import('../pages/partner/sim-card/index.vue' /* webpackChunkName: "pages/partner/sim-card/index" */))
const _09b3da78 = () => interopDefault(import('../pages/partner/statistic/index.vue' /* webpackChunkName: "pages/partner/statistic/index" */))
const _46db6b50 = () => interopDefault(import('../pages/partner/withdrawal-funds/index.vue' /* webpackChunkName: "pages/partner/withdrawal-funds/index" */))
const _9088dc2e = () => interopDefault(import('../pages/profile/history/index.vue' /* webpackChunkName: "pages/profile/history/index" */))
const _1d54f7dc = () => interopDefault(import('../pages/profile/loyalty-program/index.vue' /* webpackChunkName: "pages/profile/loyalty-program/index" */))
const _7f6dfc06 = () => interopDefault(import('../pages/profile/pay/index.vue' /* webpackChunkName: "pages/profile/pay/index" */))
const _37a73075 = () => interopDefault(import('../pages/profile/referral-program/index.vue' /* webpackChunkName: "pages/profile/referral-program/index" */))
const _440815a5 = () => interopDefault(import('../pages/profile/sale-agreement/index.vue' /* webpackChunkName: "pages/profile/sale-agreement/index" */))
const _654ae8f8 = () => interopDefault(import('../pages/profile/settings/index.vue' /* webpackChunkName: "pages/profile/settings/index" */))
const _4a885704 = () => interopDefault(import('../pages/profile/statistics/index.vue' /* webpackChunkName: "pages/profile/statistics/index" */))
const _2874cb64 = () => interopDefault(import('../pages/profile/tariffs/index.vue' /* webpackChunkName: "pages/profile/tariffs/index" */))
const _1ca42a10 = () => interopDefault(import('../pages/signup/confirm-email.vue' /* webpackChunkName: "pages/signup/confirm-email" */))
const _601c1638 = () => interopDefault(import('../pages/marketplace/account/adding-product/index.vue' /* webpackChunkName: "pages/marketplace/account/adding-product/index" */))
const _fb8f621e = () => interopDefault(import('../pages/marketplace/account/balance/index.vue' /* webpackChunkName: "pages/marketplace/account/balance/index" */))
const _76ba5de7 = () => interopDefault(import('../pages/marketplace/registration/originalIndex.vue' /* webpackChunkName: "pages/marketplace/registration/originalIndex" */))
const _49626656 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _2f965dc2 = () => interopDefault(import('../pages/bot-link/_link.vue' /* webpackChunkName: "pages/bot-link/_link" */))
const _05eeff21 = () => interopDefault(import('../pages/confirm/_token.vue' /* webpackChunkName: "pages/confirm/_token" */))
const _3566367c = () => interopDefault(import('../pages/countries/_.vue' /* webpackChunkName: "pages/countries/_" */))
const _293da421 = () => interopDefault(import('../pages/catalog/_.vue' /* webpackChunkName: "pages/catalog/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ru",
    components: {
      default: _1cf2d597
    },
    name: "index___ru"
  }, {
    path: "/cn",
    components: {
      default: _1cf2d597
    },
    name: "index___cn"
  }, {
    path: "/tr",
    components: {
      default: _1cf2d597
    },
    name: "index___tr"
  }, {
    path: "/authorization",
    component: _11cb3318,
    pathToRegexpOptions: {"strict":true},
    name: "authorization"
  }, {
    path: "/blog",
    component: _45f523c6,
    pathToRegexpOptions: {"strict":true},
    name: "blog"
  }, {
    path: "/catalog",
    component: _3c38f614,
    pathToRegexpOptions: {"strict":true},
    name: "catalog"
  }, {
    path: "/partner",
    component: _5a81a403,
    pathToRegexpOptions: {"strict":true},
    name: "partner"
  }, {
    path: "/profile",
    component: _29a528b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile"
  }, {
    path: "/registration",
    component: _36a7d318,
    pathToRegexpOptions: {"strict":true},
    name: "registration"
  }, {
    path: "/request-password",
    component: _8e4f7c58,
    pathToRegexpOptions: {"strict":true},
    name: "request-password"
  }, {
    path: "/search",
    component: _7c6946ba,
    pathToRegexpOptions: {"strict":true},
    name: "search"
  }, {
    path: "/success-payment",
    component: _121b0877,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment"
  }, {
    path: "/countries/ind222ex__",
    component: _0c6e4b8e,
    pathToRegexpOptions: {"strict":true},
    name: "countries-ind222ex__"
  }, {
    path: "/marketplace/account",
    component: _3b0c9864,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account"
  }, {
    path: "/marketplace/registration",
    component: _34b97ab8,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration"
  }, {
    path: "/partner/activations-history",
    component: _4d3ad218,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history"
  }, {
    path: "/partner/price-regulator",
    component: _3056e8f1,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator"
  }, {
    path: "/partner/sim-card",
    component: _73b7d068,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card"
  }, {
    path: "/partner/statistic",
    component: _09b3da78,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic"
  }, {
    path: "/partner/withdrawal-funds",
    component: _46db6b50,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds"
  }, {
    path: "/profile/history",
    component: _9088dc2e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history"
  }, {
    path: "/profile/loyalty-program",
    component: _1d54f7dc,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program"
  }, {
    path: "/profile/pay",
    component: _7f6dfc06,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay"
  }, {
    path: "/profile/referral-program",
    component: _37a73075,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program"
  }, {
    path: "/profile/sale-agreement",
    component: _440815a5,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement"
  }, {
    path: "/profile/settings",
    component: _654ae8f8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings"
  }, {
    path: "/profile/statistics",
    component: _4a885704,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics"
  }, {
    path: "/profile/tariffs",
    component: _2874cb64,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs"
  }, {
    path: "/signup/confirm-email",
    component: _1ca42a10,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email"
  }, {
    path: "/marketplace/account/adding-product",
    component: _601c1638,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-adding-product"
  }, {
    path: "/marketplace/account/balance",
    component: _fb8f621e,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-balance"
  }, {
    path: "/marketplace/registration/originalIndex",
    component: _76ba5de7,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration-originalIndex"
  }, {
    path: "/blog/:slug",
    component: _49626656,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug"
  }, {
    path: "/bot-link/:link?",
    component: _2f965dc2,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link"
  }, {
    path: "/confirm/:token?",
    component: _05eeff21,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token"
  }, {
    path: "/countries/*",
    component: _3566367c,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all"
  }, {
    path: "/catalog/*",
    component: _293da421,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-all"
  }, {
    path: "/*",
    component: _1cf2d597,
    pathToRegexpOptions: {"strict":true},
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
